<template>
  <div></div>
</template>
<script type>
import { getCode_api } from "@/api/common";
import Cookies from "js-cookie";
import store from "../../store/index.js";
import * as apiCommon from "@/api/common";
export default {
  data() {
    return {};
  },
  created() {
    this.getCode();
    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true,
    // });
  },
  methods: {
    getCode() {
      //let redirect_uri = encodeURIComponent(location.href);
      let redirect_uri = encodeURIComponent(
        store.state.URL + "#/index/activate"
      );
      let time = new Date().getTime()
      let url = window.location.href;
      if (url.indexOf("code") == -1) {
        window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx70deea58f0c566b2&redirect_uri=" +
          redirect_uri +
          `&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&t=${time}`);
      }
      console.log(redirect_uri, "url", this.queryString);
      let codeUrl = JSON.parse(this.queryString(url));
      let code = codeUrl.code;
      console.log(code);
      this.getUserInfo(code);
    },
    queryString(url) {
      let arr = []; //存储参数的数组
      let res = {}; //存储最终JSON结果对象
      arr = url.split("?")[1].split("&");
      for (let i = 0, len = arr.length; i < len; i++) {
        //如果有等号，则执行赋值操作
        if (arr[i].indexOf("=") != -1) {
          let str = arr[i].split("="); //str=[a,1];
          res[str[0]] = str[1];
        } else {
          //没有等号，则赋予空值
          res[arr[i]] = "";
        }
      }
      res = JSON.stringify(res); //转化为JSON字符串
      return res;
    },
    //获取用户信息
    async getUserInfo(code) {
      let data = {
        code: code
      };
      getCode_api(data).then(res => {
        console.log(res, 123123123);
        if (res.code == 0) {
          // this.datum[0]=res;
          console.log(1, res.data.access_token);
          Cookies.set("token", res.data.access_token);
          this.applyUser();
          // locache.set('milk_seller_info',res,604800);
        } else {
          this.applyUser();
          // this.datum[0] = locache.get('milk_seller_info')
        }
      });
    },
    async applyUser() {
      let res = await apiCommon.getUserInfo_api();
      console.log(res, "res");
      if (res.code == 0) {
        // 有无审核记录
        if (res.data.activate) {
          // 不是股东且审核失败或者审核成功
          if (
            res.data.user.union_shareholder == 0 &&
            (res.data.activate.audit_state == 3 ||
              res.data.activate.audit_state == 2)
          ) {
            this.$router.push("../shareholders/activate/pattern");
            return false;
          }
          if (
            res.data.user.union_shareholder == 0 &&
            res.data.activate.audit_state >= 0
          ) {
            this.$router.push(
              `../shareholders/activate/details?id=${
                res.data.activate.audit_id
              }`
            );
          } else if (res.data.user.union_shareholder == 2) {
            // 已是激活股东
            this.$router.push("../audit/audit_activate");
          }
          //   this.$router.push("../audit/audit_share")
        } else {
          if (res.data.user.member_id != 0) {
            if (res.data.user.union_member == 0) {
              this.$router.push("../shareholders/activate/pattern");
              return false
            }
          }
          // 没有审核记录且不是股东
          if (res.data.user.union_shareholder == 0) {
            this.$router.push("../shareholders/activate/index");
            //  this.$router.push("../shareholders/index")
          } else {
            // 没有审核记录，是股东
            this.$router.push("../audit/audit_activate");
          }
        }
      }
    }
  }
};
</script>
